import create, { SetState, GetState } from "zustand";
import { NavbarSlice, createNavbarSlice } from "./navbar-slice";
import { PortfolioSlice, createPortfolioSlice } from "./portfolio-slice";
import { CalendarSlice, createCalendarSlice } from "./calendar-slice";
import { ProjectSlice, createProjectSlice } from "./project-slice";
import { MarketPlaceSlice, createMarketPlaceSlice } from "./marketplace-slice";
import { AuthSlice, createAuthSlice } from "./auth-slice";
import { ToastSlice, createToastSlice } from "./toast-slice";
import { BackpackSlice, createBackpackSlice } from "./backpack-slice";
import { TeacherPageSlice, createTeacherPageSlice } from "./teacherpage-slice";
import { createLanguageSlice, LanguageSlice } from "./language-slice";
import { createSchoolSlice, SchoolSlice } from "./school-slice";
import {
  createEvaluationQuestionnaireSlice,
  EvaluationQuestionnaireSlice,
} from "./evaluationquestionnaire-slice";
import { GroupSlice, createGroupSlice } from "./group-slice";
import { ActivitySlice, createActivitySlice } from "./activity-slice";
import { AdminPageSlice, createAdminPageSlice } from "./adminpage-slice";
import { createPaymentSlice, PaymentSlice } from "./payment-slice";
import { createSocketSlice, SocketSlice } from "./socket-slice";
import { createWelcomePageSlice, WelcomePageSlice } from "./welcomepage-slice";
import { createFundamentalSlice, FundamentalSlice } from "./fundamental-slice";
import { createScheduleSlice, ScheduleSlice } from "./schedule-slice";
import { createGallerySlice, GallerySlice } from "./gallery-slice";
import { createTTASlice, TTASlice } from "./tta-slice";
import {
  createAnnouncementSlice,
  AnnouncementSlice,
} from "./announcement-slice";
import {
  createAssessmentSlice,
  AssessmentsSlice,
} from "./assessments-slice";
import { createAssessmentAnalyticsSlice, AssessmentAnalyticsSlice } from "./assessment-analytics-slice";

export type StoreState = NavbarSlice &
  PortfolioSlice &
  CalendarSlice &
  ProjectSlice &
  MarketPlaceSlice &
  AuthSlice &
  ToastSlice &
  BackpackSlice &
  TeacherPageSlice &
  LanguageSlice &
  SchoolSlice &
  GroupSlice &
  EvaluationQuestionnaireSlice &
  AdminPageSlice &
  PaymentSlice &
  SocketSlice &
  WelcomePageSlice &
  FundamentalSlice &
  ScheduleSlice &
  GallerySlice &
  ActivitySlice &
  TTASlice &
  AnnouncementSlice & 
  AssessmentsSlice &
  AssessmentAnalyticsSlice;

export type StoreSlice<T> = (
  set: SetState<StoreState>,
  get: GetState<StoreState>
) => T;

const useStore = create<StoreState>((set, get) => ({
  ...createNavbarSlice(set, get),
  ...createPortfolioSlice(set, get),
  ...createCalendarSlice(set, get),
  ...createProjectSlice(set, get),
  ...createMarketPlaceSlice(set, get),
  ...createAuthSlice(set, get),
  ...createToastSlice(set, get),
  ...createBackpackSlice(set, get),
  ...createTeacherPageSlice(set, get),
  ...createGroupSlice(set, get),
  ...createEvaluationQuestionnaireSlice(set, get),
  ...createLanguageSlice(set, get),
  ...createSchoolSlice(set, get),
  ...createAdminPageSlice(set, get),
  ...createPaymentSlice(set, get),
  ...createSocketSlice(set, get),
  ...createWelcomePageSlice(set, get),
  ...createFundamentalSlice(set, get),
  ...createScheduleSlice(set, get),
  ...createGallerySlice(set, get),
  ...createActivitySlice(set, get),
  ...createTTASlice(set, get),
  ...createAnnouncementSlice(set, get),
  ...createAssessmentSlice(set, get),
  ...createAssessmentAnalyticsSlice(set, get),
}));

export default useStore;
