import { useState, useEffect } from "react";
import { Page, Text, Image, View, StyleSheet, Font } from "@react-pdf/renderer";

import { GalleryImage } from "components/ProjectSummaryScreen/ProjectSummaryScreen";
import { pageSize, imageSrc, styles } from "../ProjectReportPDF";
import { LessonType } from "../Preview/constant";

import Background from "images/end-of-project-report/two-polaroids.png";
import Wave from "images/end-of-project-report/wave.png";
import { checkIsChineseChar, preloadImage } from "helper/helper";
import { fontFamily } from "@mui/system";

const templateStyles = StyleSheet.create({
  images: {
    position: "absolute",
    width: 400,
    height: 350,
    objectFit: "cover",
    transformOrigin: "top left",
  },
  image1: {
    top: 123,
    left: 265,
    transform: "rotate(3deg)",
  },
  image2: {
    top: 141,
    left: 771,
    transform: "rotate(-2deg)",
  },
  title: {
    position: "absolute",
    bottom: 120,
    left: 40,
    right: 40,
  },
  caption: {
    position: "absolute",
    top: 702,
    left: 40,
    right: 40,
  },
  fontStyleChinese: {
    fontFamily: "KaiTi",
  },
});

const LT2Images = ({
  lesson,
  gallery,
}: {
  lesson?: LessonType;
  gallery: GalleryImage;
}) => {

  const [preloadedImages, setPreloadedImages] = useState<(any | null)[]>([
    null,
    null,
    null,
  ]);

  useEffect(() => {
    const loadImages = async () => {
      const imagesToLoad = lesson
        ? lesson.images.map((img) => img.file)
        : gallery.images.map((img) => img.src);
  
      const loadedImages = await Promise.all(
        imagesToLoad.map(async (src) => {
          const preloadedSrc = await preloadImage(src);
          return preloadedSrc ? imageSrc(preloadedSrc) : null;
        })
      );
      setPreloadedImages(loadedImages);
    };
  
    loadImages();
  }, [lesson, gallery]);

  const hyphenationCallback = (word: string) => {
    if (word.length === 1 || !checkIsChineseChar(word)) {
      return [word];
    }
    return Array.from(word)
      .map((char) => [char, ""])
      .reduce((arr, current) => {
        arr.push(...current);
        return arr;
      }, []);
  };

  Font.registerHyphenationCallback(hyphenationCallback);

  const titleText = lesson ? lesson.title : gallery.lesson;

  return (
    <Page size={pageSize} style={styles.body}>
      <View style={styles.main} wrap={false}>
        <Image src={Wave} style={styles.background} />
        {preloadedImages[0] && (
          <Image
            src={preloadedImages[0]}
            style={[templateStyles.images, templateStyles.image1]}
          />
        )}
        {preloadedImages[1] && (
          <Image
            src={preloadedImages[1]}
            style={[templateStyles.images, templateStyles.image2]}
          />
        )}

        <Image src={Background} style={styles.background} />

        {checkIsChineseChar(titleText) ? (
          <Text
            style={[
              styles.pageTitle,
              templateStyles.title,
              templateStyles.fontStyleChinese,
            ]}
          >
            titleText
          </Text>
        ) : (
          <Text style={[styles.pageTitle, templateStyles.title]}>
            {titleText}
          </Text>
        )}

        {checkIsChineseChar(lesson?.caption) ? (
          <Text
            style={{
              textAlign: "center",
              fontFamily: "KaiTi",
              ...templateStyles.caption,
            }}
          >
            {lesson ? lesson.caption : gallery.images[0].alt}
          </Text>
        ) : (
          <Text style={[styles.textCenter, templateStyles.caption]}>
            {lesson ? lesson.caption : gallery.images[0].alt}
          </Text>
        )}
      </View>
    </Page>
  );
};

export default LT2Images;
