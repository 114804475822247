import { StoreSlice, StoreState } from "./store";

// Define the structure of the analytics state
export interface AssessmentAnalytics {
  id: number;
}

// Extend the store slice interface
export interface AssessmentAnalyticsSlice {
  assessmentAnalytics: AssessmentAnalytics;
  setAssessmentAnalytics: (id: number) => void;
}

// Create the slice
export const createAssessmentAnalyticsSlice: StoreSlice<
  AssessmentAnalyticsSlice
> = (set, get) => ({
  assessmentAnalytics: {
    id: -1, 
  },
  setAssessmentAnalytics: (id: number) => {
    set((state) => ({
      ...state,
      assessmentAnalytics: {
        ...state.assessmentAnalytics,
        id, 
      },
    }));
  },
});
