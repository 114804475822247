import { lazy } from "react";
import { Route } from "react-router-dom";
import { ASSESSMENT_ANALYTICS } from "constants/url";
import { SuspenseWrapper } from "./LittleEvaluator";
import { environment } from "environment";

const DevelopmentalGoalTrackerDetails = lazy(
  () => import("containers/AssessmentAnalytics")
);
const Tracker = lazy(
  () => import("containers/AssessmentAnalytics/DevelopmentalGoalTracker")
);
const LittleEvaluatorTracker = lazy(
  () => import("containers/AssessmentAnalytics/LittleEvaluatorTracker")
);
const AssessmentAnalyticsHome = lazy(
  () => import("containers/AssessmentAnalytics/AssessmentAnalyticsHome")
);

const AssessmentAnalyticsRoutes = (
  <>
    <Route
      path={ASSESSMENT_ANALYTICS}
      element={
        <SuspenseWrapper>
          <DevelopmentalGoalTrackerDetails />
        </SuspenseWrapper>
      }
    >
      {/* Default content for the right side */}
      <Route index element={<AssessmentAnalyticsHome />} />
      <Route
        path=":groupId/developmental-goal/:fundamentalId"
        element={<Tracker />}
      />
      <Route
        path=":groupId/little-evaluator/:fundamentalId"
        element={<LittleEvaluatorTracker />}
      />
      {/* <Route
        index
        element={
          <SuspenseWrapper>
            <AssessmentOverview />
          </SuspenseWrapper>
        }
      /> */}
    </Route>
  </>
);

export default environment.previewFeatures ? AssessmentAnalyticsRoutes : null;
